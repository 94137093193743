<script setup lang="ts">
import { APP_STORE_LINK, GOOGLE_STORE_LINK, PRIVACY_POLICY, USER_AGREEMENT } from '@/utils/const'

const props = defineProps<{
  bgColor: string
}>()

const appStore = useAppStore()
const { t } = useI18n()
const localLang = ref(process.env.LANG || 'en')

// 下载app弹窗
const onUpdateToApp = (val: boolean) => {
  if (val) zaq('bookdetails-gotoapp-click', { position: 2 })
}
const imgSrc = ref(localImage(`logo/logo-foot-${process.env.LANG || 'en'}.png`))
// 跳转dmca
const jumpDmca = () => {
  const url = window.location.origin
  const lang = useAppStore().language

  window.open(`https://www.hopwriter.com/#/dmca?site=tapon&url=${url}&lang=${lang}`)
}
</script>

<template>
  <section w-full h-347px mt-56px pt-56px pb-30px :style="{ background: props.bgColor }">
    <div class="web-container flex justify-between text-16px text-[#595959]">
      <a href="/" flex-1>
        <img
          :class="appStore.language === 'ar' ? 'w-200px' : 'h-48px'"
          :src="imgSrc"
          alt="logo"
          @error="imgSrc = localImage(`logo/logo-foot-en.png`)"
        />
      </a>
      <div flex-1>
        <div color="#8C8C8C" text-18px mb-26px>{{ t('resources') }}</div>
        <NPopover
          arrow-wrapper-class="addPopverClass"
          trigger="hover"
          placement="bottom"
          displayDirective="show"
          raw
          rounded-lg
          :onUpdate:show="onUpdateToApp"
        >
          <template #trigger>
            <div w-max-content mb-2 cursor-pointer hover="text-primary">
              {{ t('getTheApp') }}
            </div>
          </template>
          <div p-16px bg-white flex gap-15px rounded-lg overflow-hidden>
            <img
              flex-none
              rounded-lg
              w-96px
              h-96px
              :src="localImage(`${localLang === 'ar' ? 'ewm-ar.png' : 'ewm.png'}`)"
              alt=""
            />
            <div flex-none>
              <a :href="APP_STORE_LINK" target="_blank">
                <img w-134px :src="localImage('app-store.png')" alt="" />
              </a>
              <a :href="GOOGLE_STORE_LINK" target="_blank">
                <img w-134px mt-16px :src="localImage('google-play.png')" alt="" />
              </a>
            </div>
          </div>
        </NPopover>
        <a href="https://www.hopwriter.com/" target="_blank" @click="zaq('home-writer-click')">
          {{ t('toCreate') }}
        </a>
      </div>
      <div flex-1>
        <div color="#8C8C8C" text-18px mb-26px>{{ t('legal') }}</div>
        <a block mb-2 :href="USER_AGREEMENT" target="_blank">{{ t('termsOfUse') }}</a>
        <a block :href="PRIVACY_POLICY" target="_blank">{{ t('privacyPolicy') }}</a>
      </div>
      <div flex-1>
        <div color="#8C8C8C" text-18px mb-26px>{{ t('cantactUs') }}</div>
        <a href="mailto:service@tapon.com" target="_blank" class="bg-img" mb-2 flex gap-8px items-center>
          <img w-20px :src="localImage('email-icon.png')" alt="" />
          <p>service@tapon.com</p>
        </a>
        <div mb-2 flex gap-8px items-center cursor-pointer class="bg-img" @click="jumpDmca">
          <img w-20px :src="localImage('dmca-icon.png')" alt="" />
          DMCA
        </div>
      </div>
    </div>
    <p text-center mt-40 text-12px color="#8C8C8C" class="web-container">Copyright © Tapon. All Rights Reserved.</p>
  </section>
</template>

<style lang="less" scoped>
.bg-img {
  overflow: hidden;
  height: 20px;
  line-height: 20px;

  img {
    width: 20px;
    overflow: hidden;
  }

  &:hover {
    color: #ff5335;

    img {
      transform: translateY(-20px);
      filter: drop-shadow(#ff5335 0 20px);
    }
  }
}

[dir='rtl'] .bg-img:hover {
  color: #fd6b0a;

  img {
    filter: drop-shadow(#fd6b0a 0 20px);
  }
}
</style>
