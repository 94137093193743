<!--  -->
<script lang="ts" setup>
import type { IBook, ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems | { books: IBook[] }
}>()
</script>

<template>
  <section flex gap-25px>
    <a
      v-for="book in props?.info?.books"
      :key="book.novel_id"
      :href="`/bookDetail?id=${book.novel_id}`"
      w-150px
      flex-none
      cursor-pointer
      class="group"
    >
      <div w-full h-200px overflow-hidden rounded-lg>
        <img img-hover :src="book.avatar" />
      </div>
      <div mt-16px overflow-hidden>
        <h3 text-18px font-medium line-clamp-2 min-h-50px group-hover:text-primary>
          {{ book.title }}
        </h3>
        <div mt-2 color="#8C8C8C">{{ book?.category?.name }}</div>
      </div>
    </a>
  </section>
</template>

<style lang="less" scoped></style>
