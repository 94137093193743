import type { App } from '@vue/runtime-core'
import { createI18n } from 'vue-i18n'
import Cookies from 'js-cookie'

import en from '../../locales/en.json'
import es from '../../locales/es.json'
import ko from '../../locales/ko.json'
import id from '../../locales/id.json'
import pt from '../../locales/pt.json'
import th from '../../locales/th.json'
import zh_Hans from '../../locales/zh_Hans.json'
import zh_Hant from '../../locales/zh_Hant.json'
import ar from '../../locales/ar.json'
import fr from '../../locales/fr.json'
import de from '../../locales/de.json'
import ja from '../../locales/ja.json'
import ru from '../../locales/ru.json'

// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
//
const messages = {
  en,
  es,
  ko,
  id,
  pt,
  th,
  ar,
  hans: zh_Hans,
  hant: zh_Hant,
  fr,
  de,
  ja,
  ru,
}

export const install = (app: App<Element>) => {
  Cookies.set('language', process.env.LANG || 'en')
  const i18n = createI18n({
    legacy: false,
    locale: process.env.LANG || 'en',
    messages,
  })

  app.use(i18n)
}
