<!--  -->
<script lang="ts" setup>
import type { ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems | { tags: { id: number; name: string }[] }
}>()
</script>

<template>
  <section flex flex-wrap gap-16px>
    <a
      v-for="tag in props.info.tags"
      :key="tag.id"
      :href="`/typePages?tag_id=${tag.id}`"
      py-8px
      px-16px
      flex-none
      cursor-pointer
      text-16px
      rounded-full
      select-none
      hover:color-primary
      class="bg-[rgba(249,37,0,0.0500)] text-[#595959]"
    >
      {{ tag.name }}
    </a>
  </section>
</template>

<style lang="less" scoped></style>
