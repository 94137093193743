import { defineStore } from 'pinia'
import Cookies from 'js-cookie'
import { useAppStore } from './app'
import { isZh } from '@/utils/const'

export const useUserStore = defineStore('user', {
  state: () =>
    ({
      token: Cookies.get('token') || '',
      userInfo: Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo') as string) : {},
      showLogin: false,
    }) as { [v: string]: any },

  actions: {
    setUserData(name: string, data: any) {
      this[name] = data
      Cookies.set(name, typeof data === 'object' ? JSON.stringify(data) : data)
    },

    removeUserData(name: string) {
      Cookies.remove(name)
    },

    // 是否显示登录弹窗
    setShowLogin(flag: boolean) {
      this.showLogin = flag
    },

    // 登录
    login(driver: string) {
      let path = encodeURIComponent(window.location.pathname + window.location.search)
      if (window.location.pathname?.includes('login')) {
        // 活动页
        if (window.location.search?.includes('active=1')) {
          /*
           * 注意：这里 /c 后面加 & 是为了在sso页面获取原来的链接参数;
           * window.location.search?.slice(1)是为了去除前面的问号
           */
          path = encodeURIComponent(`/c&${window.location.search?.slice(1)}`)
        } else {
          path = '/c'
        }
      }
      if (driver === 'vk') {
        window.VKIDSDK.Auth.login()
      } else {
        window.location.replace(
          `${import.meta.env.VITE_BASE_ACTIVE_PATH}/h5-sso.html?z_lang=${isZh ? 'zh' : useAppStore().language}&driver=${driver}&origin=${encodeURIComponent(`${window.location.origin}/sso?path=${path}`)}`,
        )
      }
    },

    // 登出
    logout() {
      Object.keys(this.$state).forEach((key: string) => {
        this.removeUserData(key)
      })
      useUserStore().$reset()
    },
  },
})
