<!--  -->
<script lang="ts" setup>
import type { IBook, ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems | { books: IBook[] }
}>()
</script>

<template>
  <section flex gap-24px>
    <a
      v-for="book in props?.info?.books"
      :key="book.novel_id"
      :href="`/bookDetail?id=${book.novel_id}`"
      w-180px
      flex-none
      cursor-pointer
      rounded-lg
      overflow-hidden
      class="bg-[rgba(245,245,245,0.5)] group"
    >
      <div w-full h-241px overflow-hidden>
        <img img-hover :src="book.avatar" />
      </div>
      <div p-8px text-14px>
        <h3 text-16px font-medium line-clamp-2 h-44px group-hover:text-primary>
          {{ book.title }}
        </h3>
        <div mt-1 color="#8C8C8C">{{ book?.category?.name }}</div>
      </div>
    </a>
  </section>
</template>

<style lang="less" scoped></style>
