<script setup lang="ts">
const props = defineProps<{
  discount: number
}>()
</script>

<template>
  <div
    v-if="props.discount"
    rounded-full
    flex="~ gap-2px"
    items-center
    absolute
    top-1
    left-1
    p="6px 2px"
    font-medium
    leading-14px
    text="#6C4505 12px"
    class="tag-bg"
  >
    <img w-10px h-10px :src="localImage('countdown-icon.png')" alt="" />
    -{{ props.discount }}%
  </div>
</template>

<style lang="less" scoped></style>
